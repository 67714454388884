@charset "UTF-8";

.swal2-validation-message {
  margin: 0em 36px 0 !important;
  padding: .625em !important;
  background: #f5b8b9 !important;
  color: #b92525 !important;
  font-size: 1em !important;
  font-weight: 300 !important;
  &:before{
    display: none !important;
  }
}