

@media (max-width: 768px) {
  table.tableDsn{
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      //border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 1em;
      background-color: $light-gray;
      border-radius: $card-border-radious;
    }

    td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 25px;
      
    }

    td {
      display: block;
      text-align: right;
      padding: 0.5rem 0.3rem;
      width: 90%;
      min-height: 38px;
      margin: auto;
      &.key{
        &::before{
          margin-bottom: 15px;
        }
        
      }

      &:nth-of-type(1){
        border-top: none;
      }
    }

    td:last-child {
      border-bottom: 0;
    }
  }
}