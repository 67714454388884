//
// ██╗  ██╗ ██████╗ ███╗   ███╗███████╗
// ██║  ██║██╔═══██╗████╗ ████║██╔════╝
// ███████║██║   ██║██╔████╔██║█████╗
// ██╔══██║██║   ██║██║╚██╔╝██║██╔══╝
// ██║  ██║╚██████╔╝██║ ╚═╝ ██║███████╗
// ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝
//

@import "../utilities/tipographycs";
@import "../../cuba/index.scss";

/*
*Mixins.
*/
@import "../utilities/mixins";

/*
*Componentes.
*/
@import "../Components/tables";
@import "../Components/forms";
@import "../controls.scss";
@import "../Components/footer";