
/******************************************************
*      Complementary styles for custom controls       *
******************************************************/

@import "_dark_controls.scss";

.timepicker-list {
  overflow-y: scroll;
  height: 300px;
  top: -30px !important;
  width: 160px;
  visibility: visible !important;
  opacity: 1 !important;

  .list-group-item {
      cursor: pointer;   
  }
}

.timepicker-container {
  position: relative;
  height: 0;
  z-index: 2;
}

.timepicker-input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;

  select {
    color: #495057;
    font-size: 13px;
    margin: 1px;
    border: none;
  }

  i {
    margin-left: 3px;
  }
}

.inputsearch-result {
  border: gainsboro 1px solid;
}

.input-search-list {    
  position: absolute;
  max-height: 300px;
  overflow: scroll;
  z-index: 2;
  background-color: white;
  overflow-x: hidden;
  border: solid 1px;
  border-radius: 10px;
  
  > div {
      cursor: pointer;   
      border-bottom: solid gainsboro 1px;
      
      &:hover {
          background-color: gainsboro;
      }
  }
}

.input-search-close { 
  margin-right: 0px;
  text-align: end;
  width: 100%;
  z-index: 2;

  i {
    position: absolute;
    margin-left: -18px;
    margin-top: 3px;
    z-index: 2;

    &:hover {
      cursor: pointer;
      color: #e6474a;
    }
  }
}


// Checklist
.checklist-container {
  overflow-y: scroll;
  max-height: 200px;
  height: auto !important;
}


.chat-suggestions-box {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  position: absolute;
  margin-top: -150px;
  margin-bottom: 5px;
  height: 200px;
  width: 100%;
  z-index: 2;

  .list-group {
      overflow-y: scroll;

      &:hover {
      }
  }


  .icon {
      font-size: 20px;
  }

  &--floating {
    margin-top: 50px;
    margin-left: -20px;
  }
}

.chat-contacts-box {
  .list-group {
    height: 250px;
    overflow-y: scroll;
  }
  .media {
    border: none;
  }
}

.table {
  .actions {
    width: 150px;
  }
}

.locale-table {
  .fa {
      font-size: 15px;
      margin-top: 5px;
  }

  &__translate {
      background-color: #1f1f1f12;
  }

  td {
      vertical-align: middle !important;
  }

  .modified {
      td {
          color: red;
      }
  }

  /*td:nth-child(2){
      width: 45px;
  }*/
}

.chat_backdrop {
  background-color: #00000057;
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
}
.chat-transfer-container {
  height: auto;
  border-radius: 10px;
  max-width: 700px;
  margin: auto;
  margin-top: 10%;
  
  .chat-transfer-list {
    padding: 2px;
    max-height: 400px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.chat_contenedor_image_preview {
  height: auto;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
  background-color: white;
  border: 1px solid gainsboro;
  margin-top: 10%;
  padding: 20px;
  text-align: center;

  button {
     margin-top: 10px;
  }
}
.chat_contenedor_image_preview img {
  max-width: 100%;
  border: solid 1px;
}

.card > .alert-header {
    border-radius: 15px 15px 0 0 !important;
}

.log-container{
  overflow-x: hidden !important;
  max-height: 440px !important;
  overflow-y: scroll !important;

  li {
      padding: 20px;
      border-bottom: 1px solid #00000026;
  }

  .active {
      background-color: #f9000012;
      border-radius: 10px;
  }
} 

.people-list {
  height: auto !important;
  //overflow-y: scroll;
}

.history-container {
  max-height: 450px !important;
  overflow-y: auto;
}

.chat-container {
  max-height: 560px !important;
  overflow-y: auto;
}

.supervisor-container {
  max-height: 580px !important;
  overflow-y: auto;
}

.suggestions-container{
  max-height: 450px !important;
  overflow-y: auto;
}

.d-none {
  display: none;
}

.notifier-toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;

  .toast {
    .toast-header{
      justify-content: space-between;

      .btn-close{
        display: none;;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .call-chat-sidebar {
    .card-chat {
      min-height: 625px;
    }
  }

  .call-chat-body {
    .card-chat {
      min-height: 625px;
    }
  }

  .chat-msg-box {
    /*height: 290px!important;*/
  }
}

@media screen and (max-width: 500px) {
  .table {
    .actions {
      width: auto;
    }
  }
}

.chat-msg-box-readonly{
  /*height: 560px !important;*/
  margin-bottom: 0px !important;

  ul {
    margin-bottom: 15px;
  }
}

.pagination-sizes {
  margin-top: 3px;
  justify-content: center;
  
  select {
    max-width: 70px;

    option {
      text-align: center;
    }
  }
}

.ck-editor__editable {
  max-height: 200px;
  overflow-y: scroll;
}

.paginator {
  display: inline-block;
  li {
    display: inline-block;
    margin-bottom: 3px !important;
  }
}

// TinyMCE hide notifications
.tox-notifications-container {
  display: none;
}

/***********************
* BOOTSTRAP
************************/
.input-group-text {
  background-color: transparent;
  border: none;
}

.chat-tel-input {
  width: 100%;
}

.floating-chat-section{
  position: relative!important;
}

.floating-chat {    
  border-left: gainsboro solid 2px;
  position: fixed!important;
  right: 340px;
  top: 170px;
  height: 620px;
  z-index: 1;
}

.chat-container-agent {
  display: flex;
  gap: 5px;
  height: 700px;
  max-height: auto!important;
}

.chat-current {
  max-width: 340px;
  min-width: 320px;
  flex-grow: 1;
  overflow-x: hidden;

  .card-body {
    padding: 10px;
  }

  .card {
    margin-bottom: 0;
    height: 700px;
  }

  .chat-container{
    display: flex;
    flex-direction: column;
  }
  
  &--expand {
    //max-width: 75px;
    min-width: 80px;
    //width: 75px;
    .custom-scrollbar {
      overflow-x: hidden!important;
    }
  }
}
.blink {
  animation: blinking 3s infinite;
}
.chat-viewer {
  flex-grow: 3;
  min-width: 200px;
  .nav.nav-fill.nav-tabs{
    .nav-item{
      .nav-link{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
      }
    }
  }
}

.chat-plus {
  flex-grow: 1;
  min-width: 500px;
}

.chat-new {
  background-color: white;
  border-radius: 15px;
  flex-grow: 1;
  max-width: 300px;
  min-width: 300px;
}

.chat-msg-box{
  margin-right: 10px;
}

@keyframes blinking {
  0% {
    background-color: #ffffff;
    background-color: #dee2e6 #dee2e6 #fff;
  }
  100% {
    background-color: #262932;
    border-color: #1d1e26 #1d1e26 #262932;
  }
}
.files-dropzone {
  border: 2px dashed #6c5eff !important;
  background-color: #e3e0ff !important;
  padding: 20px 50px !important;
  border-radius: 10px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  cursor: pointer;
  i{
    color: #6c5eff !important;
  }
}
