/**********************************************************************
*                     DARK MODE CUSTOM CONTROLS                       *
**********************************************************************/
.dark-only {
  /* LOCALE TABLE */
  .locale-table {
    &__translate {
        background-color: #8a8a8a1a;
    }
  }

  /* TIMEPICKER LIST */
  .timepicker-list {
    background-color: #1d1e26;

    .list-group-item-primary {
      background-color: #bebfc2 !important;
      color: $dark-color !important;
    }
  }

  /* LOG CONTAINER */
  .log-container {
    li {
      border-color: $dark-card-border;
    }
  }

  /* INPUTSEARCH */
  .input-search-list {
    background-color: $dark-card-background;
    border-color: $dark-card-border;

    div {
      border-color: $dark-card-border;
      :hover {
        background-color: $dark-card-border;
      }
    }
  }
  .inputsearch-result {
    border-color: $dark-card-border !important;
  }
}
  
/**********************************************************************
*                      DARK MODE NATIVE CONTROLS                      *
**********************************************************************/
.dark-only {
  /* REACT DATEPICKER */
  .react-datepicker {
    background-color: $dark-color;

    .react-datepicker__day {
      color: gainsboro;
    }
    .react-datepicker__day--disabled {
      color: gray;
    }
  }

  /* CKEDITOR */
  .ck-editor {
    .ck-toolbar {
      border-color: $dark-color;
      background-color: $dark-color;

      svg {
        color: #b3b3b3; 
      }
    }
    
    .ck-button {
      color: #b3b3b3 !important;
    }

    .ck-editor__editable {
      border-color: $dark-card-background !important;
      background: black !important;
      color: #b3b3b3;
    }
  }

  /* TINYMCE */
  .tox-tinymce{
    border-color: $dark-color;

    .tox-toolbar__primary{
      background-color: $dark-card-background !important;

      .tox-tbtn {
        color: #b3b3b3 !important;
        
        svg {
          fill: #b3b3b3 !important;
        }

        &--enabled {
          color: $dark-card-background !important;
          svg {
            fill: $dark-card-background !important;
          }
        }
      }
    }

    .tox-statusbar {
      background-color: $dark-card-background !important;

      &__path-divider, 
      &__wordcount,
      &__path-item {
        color: #b3b3b3 !important;
      }
      
      .tox-statusbar__branding {
        a {
          color: #b3b3b3 !important;
        }
      }
    }
    .tox-toolbar__group{
      border-color: $dark-card-border !important;
    }
  }
  .tox-menu {
    background-color: $dark-card-background !important;
  }
  .chat_contenedor_image_preview {
    background-color: $dark-card-background !important;
    border-color: $dark-card-border;
  }

  /* TINYMCE DIALOGS */
  .tox-dialog-wrap__backdrop {
    background-color: #26293294 !important
  }
  .tox-textfield, .tox-listbox {
    background-color: $dark-body-background !important;
    border-color: $dark-card-border !important;
    :hover {
      background-color: $dark-body-background !important;
    }
    color: #b3b3b3 !important;
  }
  .tox-collection--list {
    .tox-collection{
      color: #b3b3b3 !important;
      &__item {
        &--active, &--enabled {
          .tox-collection__item-label{
            color: $dark-card-background !important;
          }
        }
      }
      &__item-label {
        color: #b3b3b3 !important;
      }
    } 
  }
  .tox-dialog{
    color: #b3b3b3 !important;
    background-color: $dark-card-background !important;
    border-color: $dark-card-border !important;

    .tox-textarea {
      background-color: $dark-body-background !important;
      color: #b3b3b3 !important;
    }
    &__footer, &__header {@extend .tox-dialog;}
  }
}
  